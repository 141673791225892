import store from 'store';
import authHelper from './authHelper';
import { axiosErrorHandler, callTheApServerGet } from './axiosHelper';
import { byId, convertToInt, convertToTwoDecimalInt, insertDataToElementUsingClassName, unixTimeStampInSeconds } from './utility';







export const setMtaApBasicData = ({fullName, appName, appUsername, activePlan, depositCredit , bonusCredit  ,logoLink}) => { 
    
    
    store.set('mtaApBasicData', {
        fullName,
        appName,
        appUsername,
        activePlan,

        depositCredit,
        bonusCredit,
        logoLink,
        updatedAt: unixTimeStampInSeconds(),
    });
};

export const getMtaApBasicData = () => {
    let mtaApBasicData = store.get('mtaApBasicData');

    if (!mtaApBasicData) {
        mtaApBasicData = {


            fullName: undefined,
            appName: undefined,
            appUsername: undefined,
            activePlan: 'free',

            depositCredit: undefined,
            bonusCredit: undefined,
            logoLink: undefined,
            updatedAt: undefined,
        };
    }

    return mtaApBasicData = {
        fullName: mtaApBasicData.fullName,
        appName: mtaApBasicData.appName,
        appUsername: mtaApBasicData.appUsername,
        activePlan: mtaApBasicData.activePlan,

        depositCredit:  convertToTwoDecimalInt(mtaApBasicData.depositCredit),
        bonusCredit:  convertToTwoDecimalInt(mtaApBasicData.bonusCredit),
        logoLink: mtaApBasicData.logoLink,
        updatedAt: convertToInt(mtaApBasicData.updatedAt),
    };
};

export const refreshApBasicData = () => {
    return new Promise((resolve, reject) => {
        try {
            callTheApServerGet('/basic').then((response) => {
                const res = response.data;
                // console.log(response);
     
                const { fullName, appName, appUsername, activePlan, depositCredit ,bonusCredit ,logoLink } = res;
                setMtaApBasicData({fullName, appName, appUsername, activePlan, depositCredit , bonusCredit  ,logoLink});

                resolve(true);

            }).catch((error) => {
                axiosErrorHandler(error);
                console.log(error);

            });
        } catch (error) {
            console.log(error);
            reject(error);
        }
    });

};

export const hydrateApBasicData = () => {

    try {


        if (!getMtaApBasicData().appName) {

            return authHelper.logout();
        }


        insertDataToElementUsingClassName('app-name-text-here', getMtaApBasicData().appName);
        insertDataToElementUsingClassName('full-name-text-here', getMtaApBasicData().fullName);

       

         if(getMtaApBasicData().logoLink !==''){
            const headerProfileImageElement = byId('headerProfileImageElement');
            headerProfileImageElement.src = getMtaApBasicData().logoLink;
         }
    } catch (error) {
        console.log(error);
    }

};